/* ###### Rating  ###### */

.main-rating-value {
  font-size: 40px;
  font-weight: 400;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #8f9cc0;
  margin-bottom: 0;
  letter-spacing: -.5px;
  line-height: .7;
}

.main-rating-label {
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .5px;
}

.rating-stars {
  width: 100%;
  text-align: center;

  .rating-stars-container {
    font-size: 0px;

    .rating-star {
      display: inline-block;
      font-size: 30px;
      cursor: pointer;
      padding: 4px 8px;
      color: #0002;

      &.is--active .fa-heart, &.is--hover .fa-heart {
        color: #fb0d00;
      }

      &.sm {
        display: inline-block;
        font-size: 14px;
        color: #eaedf1;
        cursor: pointer;
        padding: 5px;
      }

      &.is--active, &.is--hover {
        color: #f1c40f;
      }

      &.is--no-hover, .fa-heart .is--no-hover {
        color: #f1f1f9;
      }
    }
  }
}

.br-theme-bars-horizontal .br-widget {
  a {
    background-color: #0002;
  }
  
}

.br-theme-bars-pill .br-widget a {
  background-color: #0002;

  &.br-active, &.br-selected {
    color: white;
  }
}

.br-theme-bars-square .br-widget a {
  &.br-active, &.br-selected {
    border: 2px solid #0000002a;
  }

  border: 2px solid #0000002a;
  background-color: $white;
  color: #8f9cc0;
}

.br-theme-bars-movie .br-widget {
  a {

    background-color: #0002;
  }
}

.br-theme-bars-1to10 .br-widget {
  a {
    background-color: #0002;
  }
}

.rating-stars input {
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid #eaedf1;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


/* ###### Rating  ###### */