/* You can add global styles to this file, and also import other style files */

@import "assets/app_styles.scss";
@import "assets/plugins/web-fonts/icons.css";
@import "assets/plugins/web-fonts/font-awesome/font-awesome.min.css";
@import "assets/plugins/web-fonts/plugin.css";

:root{
  --b-radius: 8px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

tr.mat-row, tr.mat-footer-row{height: fit-content !important;}

mat-paginator:not(.show-size) .mat-paginator-page-size{display: none !important;}
.angular-editor-toolbar{
    padding: 5px 0;
    display: flex;
    height: fit-content;
    border-radius: var(--b-radius);
    background-color: #aad2;
    border-color: #0000002a !important;
}
.angular-editor-textarea{border-radius: var(--b-radius); border-color: #0000002a !important;}
.angular-editor-toolbar-set{display: inline-flex !important;}

.text-overflow{
    max-width: 100%;
    overflow: hidden;text-overflow: ellipsis;
    white-space: nowrap;
}


.swal2-container{

    padding: 0 0 0 80px !important;
    bottom: 0 !important;
    left: 0 !important;
    pointer-events: none;
    box-shadow: 0 2px 14px #0006;
  
    .swal2-popup{
      pointer-events: all;
      
      &:not(.swal2-toast){
        background-image: url(./assets/img/modal-texture.png) !important;
        background-size: cover;
        border-radius: var(--b-radius) !important;
        width: 420px !important;
  
        .swal2-content{
          font-size: 1rem !important;
        }
      }
  
      &.swal2-toast{
      margin: 8px auto !important;
      box-shadow: 0 1px 4px #0006;
      color: white;
      border-radius: var(--b-radius) !important;
      padding: 4px 10px !important;
      width: 420px !important;
    
      background-image: url(./assets/img/trianglePattern.png) !important;
      background-size: cover !important;
      // font-size: inherit !important;
    }
    }
    .swal2-actions{
      justify-content: space-between;
      padding: 0 !important;
      flex-wrap: nowrap !important;
  
      .swal2-styled{border-radius: var(--b-radius) !important; min-width: 33%; margin: 5px auto;}
      .swal2-confirm.swal2-styled{
        background-color: var(--success) !important;
        order: 2;
      }
      .swal2-cancel.swal2-styled{
        background-color: var(--danger) !important;
        order: 1;
      }
    }
  }
  

/* Time picker css */

// .timepicker-overlay{
//     z-index: 1000000 !important;

// }

// .timepicker-backdrop{
//     z-index: 1000000 !important;
// }

qr-scanner{
    video{
        height: 80vh !important;
        object-fit: cover;
    }
}


.__instructions{
  height: fit-content;
  margin: auto;
  text-align: center;
  padding: 50px 20px;
  opacity: 0.7;

  max-width: 800px;

  .fa, .zmdi, .mdi{color: var(--dark);font-size: 6em; opacity: 0.4; margin-bottom: 50px;}
  h2{color: var(--primary); font-weight: 300;}
  p{font-size: 1.2em; margin: 20px 0 0;}
}