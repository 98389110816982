/* ###### Wizard ###### */

.wizard {
  border: 1px solid #0000002a;
  background-color: $white;

  > {
    .steps {
      padding: 20px;

      > ul {
        padding: 0;
        margin-bottom: 0;
        display: flex;

        li {
          float: none;
          display: block;
          width: auto;

          .current-info {
            display: none;
          }

          .title {
            margin-left: 5px;
            white-space: nowrap;
            transition: all 0.2s ease-in-out;
          }

          + li {
            margin-left: 5px;
          }
        }
      }

      a {
        color: #8f9cc0;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover, &:active {
          color: #8f9cc0;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .number, &:hover .number, &:active .number {
          flex-shrink: 0;
          font-weight: 700;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          color: #8f9cc0;
          display: block;
          text-align: center;
          line-height: 2;
          width: 30px;
          height: 30px;
          background-color: $background;
          border-radius: 0;
          transition: all 0.2s ease-in-out;
        }
      }

      .disabled {
        display: none;

        a {
          color: #a8afc7;

          &:hover, &:active {
            color: #a8afc7;
          }
        }
      }

      .current a {

        .title, &:hover .title, &:active .title {
          display: inline-block;
        }

        .number, &:hover .number, &:active .number {
          color: $white;
          border-radius: 3px;
        }
      }

      .done a {
        color: #a8afc7;

        &:hover, &:active {
          color: #a8afc7;
        }

        .title, &:hover .title, &:active .title {
          display: none;
        }

        .number, &:hover .number, &:active .number {
          background-color: $white;
          border: 1px solid #0000002a;
        }
      }
    }

    .content {
      border-top: 1px solid #0000002a;
      border-bottom: 1px solid #0000002a;
      min-height: 150px;
      padding: 20px;

      > {
        .title {
          font-size: 18px;
          color: #8f9cc0;
          font-weight: 700;
          margin-bottom: 5px;
          display: none;

          &.current {
            display: none;
          }
        }

        .body {
          float: none;
          position: static;
          width: auto;
          height: auto;

          input.parsley-error {
            border-color: #f16d75;

            + ul {
              list-style: none !important;
            }
          }
        }
      }
    }

    .actions {
      padding: 20px;

      > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-between;

        > li:last-child a {
          background-color: #f16d75;
        }
      }

      a {
        display: block;
        padding: 9px 25px;
        line-height: 1.573;
        color: $white;
        border-radius: 4px;
		    background: #6259ca;

        &:hover, &:active {
          display: block;
          padding: 9px 25px;
          line-height: 1.573;
          color: $white;
          border-radius: 4px;
        }
      }

      .disabled a {
        background-color: var(--secondary);
        opacity: 0.6;

        &:hover, &:active {
          background-color: var(--secondary);
          opacity: 0.8;
        }
      }
    }
  }

  &.vertical > {
    .steps {
      padding: 20px;

      ul li {
        + li {
          margin-left: 10px;
        }

        .title {
          display: none;
        }
      }

      .current a .title {
        display: inline-block;
      }
    }

    .content {
      margin: 0;
      padding: 20px;
    }

    .actions {
      padding: 20px;

      ul {
        float: none;
        margin: 0;
        padding: 0;
      }
    }
  }
}

@media (min-width: 768px) {
  .wizard > .steps {
    padding: 25px;
  }
}

@media (min-width: 992px) {
  .wizard > .steps {
    padding: 20px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .wizard > .steps > ul li .title {
    transition: none;
  }
}

@media (min-width: 576px) {
  .wizard > .steps > ul li .title {
    display: none;
    margin-left: 10px;
  }
}

@media (min-width: 768px) {
  .wizard > .steps > ul li .title {
    display: inline-block;
  }
}

@media (min-width: 576px) {
  .wizard > .steps > ul li + li {
    margin-left: 20px;
  }
}

@media (min-width: 992px) {
  .wizard > .steps > ul li + li {
    margin-left: 30px;
  }
}

@media (min-width: 1200px) {
  .wizard > .steps a {
    justify-content: flex-start;

    &:hover, &:active {
      justify-content: flex-start;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .wizard > .steps a {
    .number, &:hover .number, &:active .number {
      transition: none;
    }
  }
}

@media (min-width: 576px) {
  .wizard > .steps a {
    .number, &:hover .number, &:active .number {
      font-size: 14px;
      font-weight: 600;
      width: 30px;
      height: 30px;
      vertical-align: middle;
    }
  }
}

@media (min-width: 576px) {
  .wizard > .steps .disabled {
    display: block;
  }
}

@media (min-width: 768px) {
  .wizard > .steps .done a {
    .title, &:hover .title, &:active .title {
      display: inline-block;
    }
  }
}

@media (min-width: 768px) {
  .wizard > .content {
    padding: 25px;
  }
}

@media (min-width: 992px) {
  .wizard > .content {
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .wizard > .actions {
    padding: 25px;
  }
}

@media (min-width: 992px) {
  .wizard > .actions {
    padding: 10px;
  }
}

@media (min-width: 576px) {
  .wizard.vertical > .steps {
    float: left;
    width: 20%;
  }
}

@media (min-width: 768px) {
  .wizard.vertical > .steps {
    width: 15%;
  }
}

@media (min-width: 992px) {
  .wizard.vertical > .steps {
    padding: 25px;
    width: 30%;
  }
}

@media (min-width: 576px) {
  .wizard.vertical > .steps ul {
    flex-direction: column;
  }
}

@media (min-width: 576px) {
  .wizard.vertical > .steps ul li + li {
    margin-top: 10px;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .wizard.vertical > .steps ul li + li {
    margin-top: 20px;
  }
}

@media (min-width: 992px) {
  .wizard.vertical > .steps ul li .title {
    display: block;
  }
}

@media (min-width: 992px) {
  .wizard.vertical > .steps a {
    justify-content: flex-start;
  }
}

@media (min-width: 576px) {
  .wizard.vertical > .steps .current a .title {
    display: none;
  }
}

@media (min-width: 992px) {
  .wizard.vertical > .steps .current a .title {
    display: inline-block;
  }
}

@media (min-width: 576px) {
  .wizard.vertical > .content {
    border-top-width: 0;
    border-bottom-width: 0;
    width: 80%;
    float: right;
    border-left: 1px solid #0000002a;
  }
}

@media (min-width: 768px) {
  .wizard.vertical > .content {
    width: 85%;
  }
}

@media (min-width: 992px) {
  .wizard.vertical > .content {
    width: 70%;
    padding: 25px;
  }
}

@media (min-width: 576px) {
  .wizard.vertical > .actions {
    width: 80%;
    float: right;
    border-left: 1px solid #0000002a;
  }
}

@media (min-width: 768px) {
  .wizard.vertical > .actions {
    width: 85%;
  }
}

@media (min-width: 992px) {
  .wizard.vertical > .actions {
    width: 70%;
    padding: 25px;
  }
}

.wizard-style-1 > .steps > ul {
  a {
    padding: 0;
    height: 50px;

    &:hover, &:active {
      padding: 0;
      height: 50px;
    }

    .number, &:hover .number, &:active .number {
      width: 50px;
      height: 100%;
      border: 0;
      font-size: 18px;
      font-weight: bold;
      color: #a8afc7;
      background-color: $background;
      border-radius: 0;
    }

    .title, &:hover .title, &:active .title {
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  .done a {
    .number, &:hover .number, &:active .number {
      background-color: #643ab0;
      color: $white;
    }
  }
}

.wizard-style-2 > .steps > ul {
  a {
    padding: 0;
    height: 50px;
    border-radius: 50px;

    &:hover, &:active {
      padding: 0;
      height: 50px;
      border-radius: 50px;
    }

    .number, &:hover .number, &:active .number {
      width: 50px;
      height: 100%;
      border: 2px solid $background;
      font-size: 18px;
      font-weight: bold;
      color: #a8afc7;
      background-color: $white;
    }

    .title, &:hover .title, &:active .title {
      margin-right: 20px;
    }
  }

  .done a {
    .number, &:hover .number, &:active .number {
      border-color: #6f42c1;
      color: #6f42c1;
    }
  }
}

/* ###### Wizard ###### */

/* ###### EQUAL COLUMN WIDTH STEP INDICATOR ###### */

.step-equal-width > .steps > ul {
  display: flex;

  > li {
    flex: 1;
    width: auto;
    float: none;
    flex: 1;
    width: auto;
    float: none;
  }

  display: flex;
}

/* ###### EQUAL COLUMN WIDTH STEP INDICATOR ###### */
