/* Importing node modules SCSS file. */
// @import '~bootstrap/scss/bootstrap';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import '~animate.css/animate.min.css';
@import "~simple-datatables/dist/style.css";
@import "~leaflet/dist/leaflet.css";
@import "~chartist/dist/scss/chartist.scss";

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import "~intl-tel-input/build/css/intlTelInput.css";
@import "~angular2-multiselect-dropdown/themes/default.theme.css";
@import '~dropzone/dist/min/dropzone.min.css';
@import '~ngx-toastr/toastr.css';
@import '~angular-archwizard/archwizard.css';
@import '~animate.css/animate.min.css';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/themes/dark.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import "~angular-calendar/scss/angular-calendar.scss";
@import "~flatpickr/dist/flatpickr.css";
@import '~highlight.js/scss/solarized-dark.scss';

//custom scss
@import "scss/style.scss";
// @import "css/skins.scss";
// @import "css/dark-style.scss";
// @import "css/colors/default.scss";
@import "assets/css/icon-list.css";
// @import "css/colors/color.scss";

@import "css/sidemenu/sidemenu.scss";

.pac-container {
    z-index: 2000;
    display: inline-block;
}
.md-drppicker{
    display: flex;
    top: 55px !important;
    
    &.double{left: unset !important; right: 0 !important;}
}

.btn i.typcn.typcn-eye {
    line-height: 12px;
    font-size: 1.1em;
}

app-sidemenu{
    .ps__rail-x, .ps__rail-y{display: none !important;}
}


.main-content{
    // &::before{
    //     content: '';
    //     display: block;
    //     width: 100%;
    //     height: 90vh;
    //     background-image: url(../assets/img/pattern.png);
    //     background-size: contain;
    //     background-repeat: no-repeat;
    //     background-position: top right;
    //     position: absolute;
    //     right: 0;bottom: 0;
    //     opacity: 0.2;
    //     pointer-events: none;
    //     mix-blend-mode: darken;
    // }
}

tr.mat-header-row{height: fit-content !important;}
th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type{padding-left: 6px !important;}
th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type{padding-right: 6px !important;}
.mat-sort-header-arrow{top: -2px; color: var(--primary);}

.mat-paginator-range-actions button{
    border-radius: var(--b-radius);
    border: 1px solid #0000002a;

    &:not(:last-child){margin-right: 8px;}

    .mat-button-wrapper{
        position: relative;top: -2px;
    }
    

    &:not(.mat-button-disabled){color: var(--primary);}
}

.main-sidebar-body li.nav-item.active li.nav-sub-item{pointer-events: all !important;}

.mat-table,.mat-paginator{background: none;}

.__texture{
    // background-image: url(./img/trianglePattern.png);
    // background-size: cover;
}

._disabled{
    filter: saturate(0) opacity(.6);
    pointer-events: none;
}

textarea{resize: none;}

._pill{
    width: fit-content;
    margin: auto;
    display: flex;
    padding: 3px 6px;
    border-radius: calc(var(--b-radius) / 2);
    // background-color: hsla(224, 33%, 23%, 0.102);

    span{
        height: 21px;
        padding: 2px;
        font-size: .9em;
        font-weight: 700;
        white-space: nowrap;

        &:first-child{width: 22px;}
    }

    &::before{
        border-radius: calc(var(--b-radius) / 2);
    }
    
}

@for $i from 1 to 5 {
    .text-clamp-#{$i}{
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        // white-space: break-spaces;
        display: -webkit-box;
        -webkit-line-clamp: $i;
        -webkit-box-orient: vertical;
    }
}

aw-wizard-navigation-bar li.editing .step-indicator,
// aw-wizard-navigation-bar li.completed .step-indicator,
aw-wizard-navigation-bar li.current .step-indicator{background-color: var(--warning) !important;}

aw-wizard-navigation-bar li.completed,
aw-wizard-navigation-bar li.done{
    &:not(.current){
        .step-indicator{background-color: var(--success) !important;}
    }
}


.__band{
    .opacity{opacity: 0.3;}
    &::after{
        content: attr(band-label);
        display: block;
        font-weight: 500;
        width: 130%;
        height: auto;
        padding: 8px 0;
        line-height: 1;
        color: white;
        box-shadow: 1px 3px 8px #000a;
        z-index: 2;
        text-align: center;
        background: linear-gradient(45deg, #a83bd6, #2e00d8);
        position: absolute;
        top: 30px;
        left: -52%;
        transform: rotate(-45deg);
        transform-origin: center;
    }
    &.warning::after{background: linear-gradient(45deg, #d6763b, #da0d73);}
  }

.page-header .line:first-child::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background: var(--secondary);
    margin: 5px 0 0;
}

.__disableEventMenu{
    .nav-item._highlight, app-event-select{
        filter: saturate(0);
        opacity: 0.4;
        pointer-events: none;
    }
}

.ng-select{
    .ng-select-container{background-color: transparent;}
}

select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    background-position: right 50%;
    background-repeat: no-repeat;
}