/* ###### Select2  ###### */

.select2-results__option {
  border-radius: 0;
  margin-bottom: 1px;
  font-size: 13px;
  padding: 6px 10px;
  font-size: 0.875rem;
}

.select2-container--default {
  .select2-selection--single {
    background-color: $white;
    border-color: $background;
    border-radius: 3px;
    height: 38px;
    outline: none;
    border-radius: 0;

    .select2-selection__rendered {
      color: #3c4858;
      line-height: calc(1.5em + 0.75rem + 2px) (-0.1rem);
      padding-left: 0.75rem;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .select2-selection__placeholder {
      color: #a8afc7;
    }

    .select2-selection__arrow {
      width: 30px;
      height: 38px;
      line-height: calc(1.5em + 0.75rem + 2px);

      b {
        margin-top: -3px;
      }
    }
  }

  .select2-selection--multiple {
    background-color: $white;
    border-color: $background;
    border-radius: 0;
    min-height: 38px;
    outline: none;

    .select2-selection__rendered {
      padding: 0 4px;
    }

    .select2-selection__choice {
      position: relative;
      margin-top: 5px;
      margin-right: 4px;
      padding: 3px 10px 3px 20px !important;
      border-color: transparent;
      border-radius: 0;
      color: $white;
      line-height: 1.45;
    }

    .select2-selection__choice__remove {
      color: $white;
      opacity: .5;
      font-size: 12px;
      display: inline-block;
      position: absolute;
      top: 4px;
      left: 7px;
    }
  }

  &.select2-container--focus .select2-selection--multiple {
    border-color: $background;
    border: solid #eaeafa 1px;
  }

  .select2-search--dropdown .select2-search__field {
    border-color: $background;
    border-radius: 0;
  }

  .select2-results__option[aria-selected="true"] {
    background-color: white;
  }
  
  .select2-results > .select2-results__options {
    margin: 0px;
  }

  .select2-search--inline .select2-search__field {
    margin-top: 7px;
    line-height: 26px;
    padding-left: 7px;
    opacity: 1;
  }

  &.select2-container--disabled .select2-selection__choice {
    padding-left: 10px;
    background-color: #a8afc7;

    .select2-selection__choice__remove {
      display: none;
    }
  }
}

.select2-container--open {
  .select2-selection--single, .select2-selection--multiple {
    background-color: $white;
    border-color: $background;
  }

  .select2-dropdown--above {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .select2-dropdown--below {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    top: 0;
  }
}

.select2-dropdown {
  border-color: $background;
  z-index: 200;
}

.select2-search--dropdown {
  padding-bottom: 0;
}

.has-success .select2-container--default .select2-selection--single, .parsley-select.parsley-success .select2-container--default .select2-selection--single {
  border-color: $success;
}

.has-warning .select2-container--default .select2-selection--single {
  border-color: $warning;
}

.has-danger .select2-container--default .select2-selection--single, .parsley-select.parsley-error .select2-container--default .select2-selection--single {
  border-color: #f16d75;
}

.select2-xs + .select2-container, .select2-dropdown-xs .select2-results__option {
  font-size: 12px;
}

.select2-sm + .select2-container, .select2-dropdown-sm .select2-results__option {
  font-size: 14px;
}

.select2-bd-0 + .select2-container--default .select2-selection--single {
  border-width: 0;
}

.bg-gray + .select2-container--default .select2-selection--single {
  background-color: #455473;

  .select2-selection__rendered {
    color: $background;
  }
}

.select2-dropdown {
  *::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    transition: .3s background;
  }

  &::-webkit-scrollbar-thumb {
    background: #0002;
  }

  *:hover::-webkit-scrollbar-thumb {
    background: #adb5bd;
  }
}


/* ###### Select2  ###### */